<template>
  <div
    class="min-h-screen"
    :class="[
      $theme.baseBg,
      [
        'setting',
        'stats',
        'home',
        'notifications',
        'signals',
        'language',
        'orders',
        'refs',
        'faq',
        'tariff',
        'profile',
        'security',
        'show-signal',
        'ref-stats',
        'incomplete',
        'terms',
        'link-card',
        'payment-cards',
        'user-logs',
      ].includes($route.name) ? $theme.secondBaseBg : '',
      $theme.baseTextColor,
    ]"
  >
    <template v-if="!enabledSignals.length">
      <EmergencyAlerts />
    </template>
  <router-view></router-view></div>
  <template v-if="isShowNavbar">
    <AppNavbar />
  </template>
  <template v-if="$settings.support_links">
    <SupportWidget :links="$settings.support_links" />
  </template>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import SupportWidget from "@/components/SupportWidget.vue";
import EmergencyAlerts from "@/components/elem/EmergencyAlerts.vue";

export default {
  name: 'App',

  components: {
    SupportWidget,
    AppNavbar,
    EmergencyAlerts,
  },

  computed: {
    isShowNavbar() {
      return (this.$user.reg_status === 'COMPLETED' || this.$user.reg_status === 'SKIP_SETUP')
          && this.$route.name !== 'two-factor-auth';
    },

    enabledSignals() {
      return this.$store.state.enabledSignals;
    }
  },

  data() {
    return {
      telegramReady: false,
    }
  },

  mounted() {
    console.log(this.$user.settings.signals);
    this.$store.commit('updateEnabledSignals', this.$user.settings.signals);

    if (window.Telegram && window.Telegram.WebApp) {
      this.telegramReady = true

      if (window.Telegram.WebApp.isVersionAtLeast('7.0')) {
        window.Telegram.WebApp.SettingsButton.show();

        window.Telegram.WebApp.SettingsButton.onClick(() => {
          this.$router.push({ name: 'setting' });
        });
      }
    }
  },
}
</script>