<template>
  <div class="px-4 pt-5 pb-10">
    <h3 class="text-3xl font-bold mb-4">
      {{ $t('link_card') }}
    </h3>
    <div v-if="successMessage" class="alert alert-success">
      {{ successMessage }}
    </div>
    <form @submit.prevent="handleSubmit" id="payment-form">
      <div class="mb-4">
        <div :class="[$theme.chartTheme]">
          <label for="card-element" class="mb-3" :class="[$theme.cardLabelTextColor]">{{ $t('card_data') }}</label>
          <div ref="cardElement" class="mt-3 rounded-xl px-3 py-2.5 text-base" :class="[$theme.cardBg, $theme.baseTextColor]"></div>
          <div id="card-errors" role="alert" :class="[$theme.dangerPnlColor, 'text-sm']">{{ errorMessage }}</div>
        </div>
      </div>
      <button
        type="submit"
        class="flex w-full items-center text-white justify-center rounded-xl px-3 py-3 font-semibold focus-visible:outline"
        :class="[
          $theme.inverseBaseBg
        ]">{{ $t('link_card') }}</button>
    </form>
    <div class="opacity-50 text-sm text-center mt-2">{{ $t('verification_card_note') }}</div>
  </div>
</template>

<script>
import {addBackButton, hideBackButton} from "@/helpers/telegramHelper";
import { loadStripe } from "@stripe/stripe-js";

export default {
  data() {
    return {
      publicKey: null,
      clientSecret: null,
      userName: null,
      stripe: null,
      cardElement: null,
      elements: null,
      errorMessage: "",
      successMessage: "",
    };
  },
  mounted() {
    addBackButton(this.$router);

    this.loadPublicData();
  },

  beforeUnmount() {
    hideBackButton();
  },
  methods: {
    loadPublicData() {
      this.$api.get('/stripe/public-data').then((result) => {
        this.publicKey = result.data.public_key;
        this.loadClientSecret();
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },

    loadClientSecret() {
      this.$api.get('/stripe/setup-intent')
          .then((response) => {
            this.clientSecret = response.data.client_secret;
            this.initializeStripe();
          })
          .catch((e) => {
            alert("Ошибка загрузки client_secret: " + e.message);
          });
    },

    async initializeStripe() {
      this.stripe = await loadStripe(this.publicKey);
      this.elements = this.stripe.elements();

      const themeStyles = {
        light: {
          placeholder: '#b6b6b6',
          color: '#2c2c2c',
        },
        dark: {
          placeholder: '#ffffff87',
          color: '#fff',
        },
      };

      const style = {
        base: {
          color: themeStyles[this.$theme.chartTheme].color,
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          iconColor: themeStyles[this.$theme.chartTheme].placeholder,
          "::placeholder": {
            color: themeStyles[this.$theme.chartTheme].placeholder,
          },
        },
        invalid: {
          color: "#e8330b",
          iconColor: "#e8330b",
        },
      };

      const card = this.elements.create("card", { style });
      card.mount(this.$refs.cardElement);

      card.on("change", (event) => {
        this.errorMessage = event.error ? event.error.message : "";
      });

      this.cardElement = card;
    },

    async handleSubmit() {
      this.errorMessage = "";
      this.successMessage = "";

      if (!this.stripe || !this.cardElement) {
        this.errorMessage = "Error init Stripe.";
        return;
      }

      try {
        const { setupIntent, error } = await this.stripe.confirmCardSetup(
            this.clientSecret,
            {
              payment_method: {
                card: this.cardElement,
                billing_details: { name: this.$user.name || "Unknown User" },
              },
            }
        );

        if (error) {
          this.errorMessage = error.message || "Error link card.";
          return;
        }

        const response = await this.$api.post(
            "/stripe/bind-card",
            {
              payment_method: setupIntent.payment_method,
              user_id: this.$user.id
            }
        );

        if (response.status === 200) {
          this.$router.push({name: 'payment-cards'});
        } else {
          this.errorMessage = response.data.message || "Server error.";
        }
      } catch (err) {
        // Обработка непредвиденных ошибок
        this.errorMessage =
            err.response?.data?.message || err.message || "Error.";
      }
    }
  },
};
</script>