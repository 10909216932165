export function formatedDate(dateString) {
    if (!dateString) {
        return ''
    }
    const date = new Date(dateString);

    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour12: false
    };

    return date.toLocaleString('en-GB', options).replace(',', '');
}