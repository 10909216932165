<template>
  <div>
    <h3 class="text-3xl font-bold mb-5">{{ title || $t('signals') }}</h3>
    <div v-html="desc" class="mt-2 mb-4 text-sm opacity-70 prose max-w-none" :class="[$theme.baseTextColor]"></div>

    <template v-if="signals.length && !loading">
      <div class="rounded-xl" :class="[$theme.cardBg]">
        <div
            v-for="(signal, signalIndex) in signals"
            :key="signal.id"
            class="px-3 relative"
        >
          <SwitchGroup
              as="div"
              :class="[
                  'flex items-center justify-between py-2',
                  signalIndex === signals.length - 1 ? '' : 'border-b',
                  $theme.cardBorderColor
                ]"
          >
                <span class="flex flex-grow flex-col">
                  <SwitchLabel
                      as="span"
                      class="flex items-center justify-between text-sm font-medium leading-6"
                      :class="[$theme.baseTextColor]"
                      passive
                  >
                    <div
                        :class="[
                        allowedSignalsIds.indexOf(signal.id) === -1 ? 'opacity-60' : ''
                      ]"
                    >
                      <div class="flex items-center">
                        <router-link
                            :to="`/signals/${signal.id}`"
                            class="mr-1 flex items-center"
                        >
                          <QuestionMarkCircleIcon class="w-4 h-4" :class="[$theme.cardLabelTextColor]" />
                          <span class="ml-1">{{ signal.name }}</span>
                        </router-link>
                        <template v-if="signal.level_risk === 'low'">
                          <TheTooltip
                              :id="`${signal.id}_${signal.max_leverage}`"
                              :message="$t('max_leverage')"
                          >
                            <span class="text-xs text-green-custom">
                              x{{ signal.max_leverage }}
                            </span>
                          </TheTooltip>
                        </template>
                        <template v-if="signal.level_risk === 'medium'">
                          <TheTooltip
                              :id="`${signal.id}_${signal.max_leverage}`"
                              :message="$t('max_leverage')"
                          >
                            <span class="text-xs text-yellow-500">
                              x{{ signal.max_leverage }}
                            </span>
                          </TheTooltip>
                        </template>
                        <template v-if="signal.level_risk === 'high'">
                          <TheTooltip
                              :id="`${signal.id}_${signal.max_leverage}`"
                              :message="$t('max_leverage')"
                          >
                            <span class="text-xs text-red-600">
                              x{{ signal.max_leverage }}
                            </span>
                          </TheTooltip>
                        </template>
                      </div>

                      <div class="leading-none">
                        <template v-if="signal.level_risk === 'low'">
                          <TheTooltip
                              :id="`${signal.id}_${signal.max_leverage}_lowrisk`"
                              message="Торговая пара требует обеспечение на счету 1 к 6 относительно суммы в торгах."
                          >
                            <span
                                class="text-xs text-green-custom flex items-center"
                            >
                              <BoltIcon class="w-3 mr-1" /> {{ $t('low_risk') }}
                            </span>
                          </TheTooltip>
                        </template>
                        <template v-if="signal.level_risk === 'medium'">
                          <TheTooltip
                              :id="`${signal.id}_${signal.max_leverage}_mediumrisk`"
                              message="Торговая пара требует обеспечение на счету 1 к 8 относительно суммы в торгах."
                          >
                            <span
                                class="text-xs text-yellow-500 flex items-center"
                            >
                              <BoltIcon class="w-3 mr-1" />
                              {{ $t('medium_risk') }}
                            </span>
                          </TheTooltip>
                        </template>
                        <template v-if="signal.level_risk === 'high'">
                          <TheTooltip
                              :id="`${signal.id}_${signal.max_leverage}_highrisk`"
                              message="Торговая пара требует обеспечение на счету 1 к 12 относительно суммы в торгах."
                          >
                            <span
                                class="text-xs text-red-600 flex items-center"
                            >
                              <BoltIcon class="w-3 mr-1" />
                              {{ $t('high_risk') }}
                            </span>
                          </TheTooltip>
                        </template>
                      </div>
                    </div>
                    <div
                        class="flex items-center mr-3"
                        :class="[
                        allowedSignalsIds.indexOf(signal.id) === -1 ? 'opacity-60' : ''
                      ]"
                    >
                      <template
                          v-for="(allow_exchange, aeIdx) in signal.exchanges"
                          :key="aeIdx"
                      >
                        <TheTooltip
                            :id="`${allow_exchange}_${signal.id}`"
                            :message="capitalizeFirstLetter(allow_exchange)"
                        >
                          <img
                              :src="`/img/${allow_exchange}.png`"
                              alt=""
                              class="w-5 rounded-full ml-1"
                          />
                        </TheTooltip>
                      </template>
                    </div>
                  </SwitchLabel>
                </span>
            <template v-if="allowedSignalsIds.indexOf(signal.id) === -1">
              <TheTooltip
                  :id="`${signal.id}_${signal.max_leverage}_lock`"
                  :message="`${$t('trading_pair_available_in_tariffs')} ${renderTariffNames(signal.tariffs)}`"
              >
                <LockClosedIcon class="w-5 opacity-30" />
              </TheTooltip>
            </template>
            <template v-else>
              <Switch
                  @click="toggleSignal(signal.id)"
                  :class="[
                      signalEnabled.indexOf(signal.id) !== -1
                        ? $theme.switchBgActive
                        : $theme.switchBgInactive,
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2',
                     $theme.switchRingFocus
                    ]"
              >
                    <span
                        aria-hidden="true"
                        :class="[
                        signalEnabled.indexOf(signal.id) !== -1
                          ? 'translate-x-5'
                          : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      ]"
                    />
              </Switch>
            </template>
          </SwitchGroup>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="rounded-xl overflow-hidden mt-4" :class="[$theme.cardBg]">
        <LoadingBlock />
      </div>
    </template>
  </div>
</template>

<script>
import TheTooltip from "@/components/elem/TheTooltip.vue";
import {Switch, SwitchGroup, SwitchLabel} from '@headlessui/vue'
import {BoltIcon, QuestionMarkCircleIcon, LockClosedIcon} from "@heroicons/vue/16/solid";
import {capitalizeFirstLetter} from "@/helpers/stringHelper"
import LoadingBlock from "@/components/LoadingBlock.vue";

export default {
  props: ['title', 'desc'],
  components: {
    LoadingBlock,
    Switch,
    SwitchGroup,
    SwitchLabel,
    TheTooltip,
    BoltIcon,
    QuestionMarkCircleIcon,
    LockClosedIcon
  },

  computed: {
    isAllSignalDisabled() {
      return this.signalEnabled.length === 0
    },

    tariffSignals() {
      if (!this.user) {
        return [];
      }

      if (!this.user.tariff) {
        return [];
      }

      return this.user.tariff.signals;
    },

    allowedSignalsIds() {
      const ids = [];

      for (const signal of this.tariffSignals) {
        ids.push(signal.id);
      }

      return ids;
    }
  },

  methods: {
    renderTariffNames(tariffs) {
      return tariffs.map(tariff => ' - ' + tariff.title).join('<br>');
    },

    toggleDisableAllSignals() {
      if (this.signalEnabled.length > 0) {
        this.signalEnabled = [];
      } else {
        this.signalEnabled = this.signals.map(signal => signal.id);
      }

      this.updateUserSettings();
    },

    toggleSignal(id) {
      console.log('Change signal: ', id);

      const index = this.signalEnabled.indexOf(id);
      if (index === -1) {
        this.signalEnabled.push(id);
      } else {
        this.signalEnabled.splice(index, 1);
      }

      this.updateUserSettings()
    },

    updateUserSettings() {
      this.$api.post('/user/update-settings', {
        signals: this.signalEnabled,
      }).then((result) => {
        console.log('Settings updated.', result)
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },

    loadSignals() {
      this.$api.get('/signals').then((result) => {
        this.signals = result.data;

      }).catch((e) => {
        console.error(e);
        this.error = e;
      });
    },

    loadUser() {
      this.$api.get('/user').then((result) => {
        this.user = result.data.data
        this.signalEnabled = []

        if (this.user.settings) {
          this.signalEnabled = this.user.settings.signals || [];
        }

        this.loading = false;

        this.loadSignals();
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },
  },

  data() {
    return {
      loading: true,
      signalEnabled: [],
      signals: [],
      error: null,
      capitalizeFirstLetter
    }
  },

  mounted() {
    this.loadUser();
  },
}
</script>