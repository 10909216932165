<template>
  <div class="pb-5">
    <template v-if="tariffInfo.status === 'no_tariff' || isChangeTariff">
      <h3 class="text-3xl font-bold mb-4">
        <template v-if="title">
          {{ title }}
        </template>
        <template v-else>
          {{ isChangeTariff ? $t('change_tariff') : $t('select_tariff') }}
        </template>
      </h3>
      <template v-if="desc">
        <div v-html="desc" class="mt-2 mb-4 opacity-70 text-sm prose max-w-none" :class="[$theme.baseTextColor]"></div>
      </template>
      <template v-if="isChangeTariff">
        <div class="rounded-xl p-4 mb-4" :class="[$theme.outlineBtnBg]">
          <div class="flex">
            <div class="flex-shrink-0">
              <InformationCircleIcon class="h-5 w-5" :class="[$theme.navActiveTextColor2]" aria-hidden="true" />
            </div>
            <div class="ml-3 flex-1 md:flex md:justify-between">
              <p class="text-sm" :class="[$theme.navActiveTextColor2]">
                {{ $t('tariff_alert') }}
              </p>
            </div>
          </div>
        </div>
      </template>
      <template v-if="loading">
        <div class="rounded-xl" :class="[$theme.cardBg]">
          <LoadingBlock />
        </div>
      </template>
      <template v-else>
        <fieldset aria-label="Server size">
          <RadioGroup v-model="selectedTariff" class="space-y-2">
            <RadioGroupOption
                as="template"
                v-for="tariff in tariffs"
                :key="tariff.id"
                :value="tariff"
                :aria-label="tariff.id"
                :aria-description="`${tariff.id} per month`" v-slot="{ }"
                :class="[
                exchanges.length === 0 && parseInt(tariff.type_paid) === 2 ? 'pointer-events-none' : ''
              ]"
            >
              <div :class="[
                selectedTariff.id === tariff.id ? '' : '',
                exchanges.length === 0 && parseInt(tariff.type_paid) === 2 ? 'opacity-60' : '',
                'relative flex cursor-pointer rounded-xl px-4 py-2 outline-none flex justify-between',
                $theme.cardBg
              ]">
                <span class="flex items-center">
                  <span class="flex flex-col text-sm">
                    <span class="font-medium flex items-center" :class="[$theme.baseTextColor]">
                      <template v-if="exchanges.length === 0 && parseInt(tariff.type_paid) === 2">
                        <LockClosedIcon class="w-4 mr-1" />
                      </template>
                      {{ tariff.title }}
                    </span>
                    <span :class="[$theme.cardLabelTextColor]">
                      <span class="block inline">{{ formattedAmount(tariff.balance[0]) }} - {{ formattedAmount(tariff.balance[1]) }}</span>
                    </span>
                  </span>
                </span>
                <span class="mt-2 flex w-24 text-sm mt-0 flex-col">
                <span class="font-medium pr-6" :class="[$theme.baseTextColor]">
                  <template v-if="parseInt(tariff.type_paid) === 1">
                    {{ formattedAmount(tariff.price) }}
                  </template>
                  <template v-if="parseInt(tariff.type_paid) === 2">
                    {{ tariff.rollback_percentage }}%
                  </template>
                </span>
                <span :class="[$theme.cardLabelTextColor]">
                  {{ tariff.days }} {{ $t('days') }}
                </span>
              </span>
                <CheckCircleIcon :class="[selectedTariff.id !== tariff.id ? 'invisible' : '', 'h-5 w-5 absolute top-2 right-2', $theme.navActiveTextColor2]" aria-hidden="true" />
                <span :class="[selectedTariff.id === tariff.id ? 'border' : 'border-2', selectedTariff.id === tariff.id ? $theme.radioActiveBorderColor : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']" aria-hidden="true" />
              </div>
            </RadioGroupOption>
          </RadioGroup>
        </fieldset>
        <template v-if="selectedTariff">
          <div class="mt-6 lg:col-start-3 lg:row-end-1">
            <h2 class="sr-only">{{ $t('info_about_selected_tariff') }}</h2>
            <div class="rounded-xl" :class="[$theme.cardBg]">
              <div class="flex flex-wrap">
                <div class="flex-auto pl-4 pt-4 pb-4">
                  <div class="text-base font-semibold leading-6" :class="[$theme.baseTextColor]">
                    {{ selectedTariff.title }}
                    <template v-if="parseInt(selectedTariff.type_paid) === 1">
                      ${{ formattedAmount(selectedTariff.price) }}
                      <div class="text-sm font-normal" :class="[$theme.cardLabelTextColor]">{{ selectedTariff.days }} {{ $t('days') }}</div>
                    </template>
                    <template v-if="parseInt(selectedTariff.type_paid) === 2">
                      {{ selectedTariff.rollback_percentage }}% Rollback
                      <div class="text-sm font-normal" :class="[$theme.cardLabelTextColor]">{{ selectedTariff.days }} {{ $t('days') }}</div>
                    </template>
                  </div>
                </div>
                <div class="text-sm grid grid-cols-2 w-full flex-none gap-x-4 border-t px-4 pt-4" :class="[$theme.cardBorderColor]">
                  <div class="flex-none" :class="[$theme.cardLabelTextColor]">
                    {{ $t('trade_balance') }}
                  </div>
                  <div class="text-sm" :class="[$theme.baseTextColor]">
                    {{ formattedAmount(selectedTariff.balance[0]) }} - {{ formattedAmount(selectedTariff.balance[1]) }}
                  </div>
                </div>
                <div class="mt-2 text-sm grid grid-cols-2 w-full flex-none gap-x-4 px-4 pb-4">
                  <div class="flex-none" :class="[$theme.cardLabelTextColor]">
                    {{ $t('max_positions') }}
                  </div>
                  <div class="text-sm" :class="[$theme.baseTextColor]">
                    {{ selectedTariff.max_positions }}
                  </div>
                </div>
                <div v-if="selectedTariff.desc[this.$user.language_code]" class="text-sm flex w-full flex-none gap-x-4 px-4 pb-4">
                  {{ selectedTariff.desc[this.$user.language_code] }}
                </div>

                <template v-if="selectedTariff.children && selectedTariff.children.length > 0">
                  <div class="w-full border-t px-4 pt-2 pb-4" :class="[$theme.cardBorderColor]">
                    <fieldset>
                      <div class="space-y-5">
                        <div v-for="childTariff in selectedTariff.children" :key="childTariff.id" class="relative flex items-start">
                          <div class="flex h-6 items-center">
                            <input
                                v-model="formPackages"
                                :id="`child_t_${childTariff.id}`"
                                type="checkbox"
                                class="h-4 w-4 rounded"
                                :class="[$theme.navActiveTextColor2, $theme.inputRingFocusColor, $theme.inputBorderColor, $theme.checkboxBgInactive]"
                                :value="childTariff"
                            />
                          </div>
                          <div class="ml-3 text-sm leading-6">
                            <label :for="`child_t_${childTariff.id}`" class="font-medium" :class="[$theme.baseTextColor]">
                              {{ childTariff.title }} / {{ childTariff.days }} {{ $t('month') }}
                            </label>
                            <p class="font-medium mb-1" :class="[$theme.baseTextColor]">
                              {{ formattedAmount(childTariff.price) }}
                            </p>
                            <TheTooltip :id="`child_tariff_${childTariff.id}`" :message="childTariff.desc">
                              <a href="javascript:void(0)" class="opacity-50 flex items-center">
                                <InformationCircleIcon class="w-4 mr-1" />
                                {{ $t('read_more') }}
                              </a>
                            </TheTooltip>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </template>

                <div v-if="selectedTariff.type_paid == 1 && selectedTariff.max_months > 1" class="w-full border-t px-4 pt-2 pb-4" :class="[$theme.cardBorderColor]">
                  <Listbox as="div" v-model="selectedMonths">
                    <ListboxLabel class="block text-sm font-medium leading-6" :class="[$theme.baseTextColor]">
                      {{ $t('number_of_months') }}
                    </ListboxLabel>
                    <div class="relative mt-2">
                      <ListboxButton
                          class="relative w-full cursor-default rounded-xl py-2 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset focus:outline-none focus:ring-2"
                          :class="[$theme.cardBg, $theme.baseTextColor, $theme.inputRingColor, $theme.inputRingFocusColor]"
                      >
                          <span class="block truncate">
                            {{ selectedMonths ? selectedMonths.label : ''}}
                          </span>
                        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
                      </ListboxButton>

                      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                        <ListboxOptions class="absolute bottom-full z-10 mt-1 max-h-60 w-full overflow-auto rounded-xl bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          <ListboxOption as="template" v-for="(tariffMonth, tariffMonthIndex) in months" :key="tariffMonthIndex" :value="tariffMonth" v-slot="{ active, selected }">
                            <li :class="[active ? 'bg-blue-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                              <div class="flex items-center">
                                  <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                                  {{ tariffMonth.label }}
                                </span>
                                <template v-if="tariffMonth.conditions">
                                  <div class="flex items-center rounded-full bg-orange-50 text-orange-500 text-sm px-2 py-0.5 ml-2">
                                    <FireIcon class="w-4 h-4 mr-2" /> {{ formattedAmount(tariffMonth.conditions.price) }}
                                  </div>
                                </template>
                              </div>
                              <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                </span>
                            </li>
                          </ListboxOption>
                        </ListboxOptions>
                      </transition>
                    </div>
                  </Listbox>
                </div>
                <div v-if="selectedTariff.type_paid == 1" class="w-full border-t border-gray-900/5 px-4 pt-2 pb-2">
                  <template v-if="formPackages.length > 0">
                    <div v-for="packageItem in formPackages" :key="packageItem.id" class="flex items-center justify-between mb-1">
                      <div class="text-sm opacity-70">{{ packageItem.title }}</div>
                      <div class="font-semibold">+ {{ formattedAmount(packageItem.price) }}</div>
                    </div>
                  </template>
                  <div class="flex items-start justify-between">
                    <div class="text-sm opacity-70">{{ $t('to_pay') }}</div>
                    <div class="font-semibold text-xl">{{ formattedAmount(total) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <div class="mt-6 w-full">
          <a
              @click="onSelectTariff"
              href="javascript:void(0)"
              :class="[formProcessing ? 'opacity-50 pointer-events-none' : '', $theme.inverseBaseBg]"
              class="flex w-full items-center justify-center rounded-xl px-3 py-3 font-semibold text-white shadow-sm"
          >
            <template v-if="formProcessing">
              <ButtonSpinner />
            </template>
            {{ isChangeTariff ? $t('change_tariff') : $t('select_tariff') }}
          </a>

          <div v-if="isChangeTariff" class="mt-3 grid grid-cols-1">
            <button
                @click="isChangeTariff = false"
                class="flex w-full items-center justify-center rounded-xl px-3 py-3 font-semibold"
                :class="[$theme.placeBg, $theme.cardLabelTextColor]"
            >
              {{ $t('cancel') }}
            </button>
          </div>
        </div>

        <template v-if="formErrorMessage !== ''">
          <div class="rounded-lg bg-red-50 p-4 mt-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-red-800">{{ $t('error') }}</h3>
                <div class="mt-2 text-sm text-red-700">
                  <ul role="list" class="list-disc space-y-1 pl-5">
                    <li>{{ formErrorMessage }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>
    </template>
    <template v-if="(tariffInfo.status === 'active' || tariffInfo.status === 'expired') && !isChangeTariff">
      <h3 class="text-3xl font-bold mb-5">
        {{ $t('your_tariff') }}
      </h3>
      <div class="rounded-xl mb-3 flex items-center justify-between px-3 py-2" :class="[$theme.cardBg]">
        <div class="w-full">
          <dl class="divide-y" :class="[$theme.cardDivideColor1]">
            <div class="py-3 grid grid-cols-2">
              <dt class="text-sm leading-6" :class="[$theme.cardLabelTextColor]">{{ $t('tariff') }}</dt>
              <dd class="text-sm leading-6" :class="[$theme.baseTextColor]">
                {{ tariffInfo.tariff.title[$user.language_code] }}
              </dd>
            </div>
            <div class="py-3 grid grid-cols-2">
              <dt class="text-sm leading-6" :class="[$theme.cardLabelTextColor]">{{ $t('status') }}</dt>
              <dd class="text-sm leading-6" :class="[$theme.baseTextColor]">
                <template v-if="tariffInfo.status === 'active'">
                  <span class="bg-green-600 text-sm px-3 py-0.5 rounded-full text-white">{{ $t('active') }}</span>
                </template>
                <template v-if="tariffInfo.status === 'expired'">
                  <span class="bg-red-500 text-sm px-3 py-0.5 rounded-full text-white">{{ $t('time_expired') }}</span>
                </template>
                <template v-if="tariffInfo.status === 'no_tariff'">
                  <span class="bg-gray-200 text-sm px-3 py-0.5 rounded-full">{{ $t('tariff_not_select') }}</span>
                </template>
              </dd>
            </div>
            <div class="py-3 grid grid-cols-2">
              <dt class="text-sm leading-6" :class="[$theme.cardLabelTextColor]">{{ $t('paid_to') }}</dt>
              <dd class="text-sm leading-6" :class="[$theme.baseTextColor]">
                {{ tariffInfo.expires_at_formatted }}
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <template v-if="tariffInfo.tariff.trial_days > 0 && !loadingPaymentCards && paymentCards.length === 0">
        <div class="mt-5">
          <div class="mb-2 text-sm text-center">Чтобы воспользоваться пробным периодом привяжите вашу карту</div>
          <router-link
              to="/link-card"
              class="flex w-full items-center text-white justify-center rounded-xl px-3 py-3 font-semibold focus-visible:outline"
              :class="[
              $theme.inverseBaseBg
            ]"
          >
            Привязать карту
          </router-link>
        </div>
      </template>

      <div class="mt-5 grid grid-cols-1">
        <button
            @click="onShowChangeTariff"
            class="flex w-full items-center justify-center rounded-xl px-3 py-3 font-semibold"
            :class="[$theme.placeBg, $theme.cardLabelTextColor]"
        >
          {{ $t('change_tariff') }}
        </button>
      </div>
    </template>
  </div>

  <TransitionRoot as="template" :show="openSelectPaymentProvider">
    <Dialog class="relative z-50" @close="openSelectPaymentProvider = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-t-xl bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all w-full">
              <div>
                <h2 class="font-bold text-2xl text-gray-900">{{ $t('payment_method') }}</h2>
                <div>
                  <template v-if="loadingPaymentProviders">
                    <LoadingBlock />
                  </template>
                  <template v-else>
                    <a
                        @click="onPayNow(paymentProvider)"
                        v-for="paymentProvider in paymentProviders"
                        :key="paymentProvider.id"
                        href="javascript:void(0)"
                        class="flex items-center border p-2 rounded-lg mt-4"
                    >
                      <div class="pr-3">
                        <img :src="`/img/pay/${paymentProvider.payment_provider}.png`" class="w-10 h-10 rounded-lg" />
                      </div>
                      <div>
                        <div>{{ paymentProvider.title }}</div>
                      </div>
                    </a>
                  </template>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import LoadingBlock from '@/components/LoadingBlock.vue'
import { RadioGroup, RadioGroupOption } from '@headlessui/vue'
import {CheckCircleIcon, XCircleIcon, CheckIcon, ChevronUpDownIcon, FireIcon, InformationCircleIcon, LockClosedIcon} from '@heroicons/vue/20/solid'
import ButtonSpinner from '@/components/ButtonSpinner.vue'
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions
} from '@headlessui/vue'
import {formattedAmount} from "@/helpers/numberHelper";
import TheTooltip from "@/components/elem/TheTooltip.vue";

export default {
  props: [
      'title',
      'desc'
  ],
  components: {
    TheTooltip,
    XCircleIcon,
    LoadingBlock,
    RadioGroup,
    RadioGroupOption,
    CheckCircleIcon,
    ButtonSpinner,
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
    FireIcon,
    InformationCircleIcon,
    LockClosedIcon,
  },

  computed: {
    tariffInfo() {
      return this.$user.tariff;
    },

    total() {
      if (!this.selectedTariff) {
        return 0.00;
      }

      for (const condition of this.selectedTariff.conditions) {
        if (condition && parseInt(condition.months) === parseInt(this.selectedMonths.value)) {
          return condition.price;
        }
      }

      let additional = 0.00;
      if (this.formPackages.length) {
        for (const item of this.formPackages) {
          additional += parseFloat(item.price);
        }
      }

      return (this.selectedTariff.price * this.selectedMonths.value) + parseFloat(additional);
    },

    months() {
      const res = [];
      let conditions = null;
      let count = 1;

      if (this.selectedTariff && this.selectedTariff.conditions) {
        for (const tariffMonth of this.tariffMonths) {
          conditions = null;

          for (const condition of this.selectedTariff.conditions) {
            if (condition && parseInt(condition.months) === parseInt(tariffMonth.value)) {
              conditions = condition;
            }
          }

          if (count <= this.selectedTariff.max_months) {
            res.push({
              label: tariffMonth.label,
              value: tariffMonth.value,
              conditions: conditions,
            });
          }

          count++;
        }
      } else {
        for (const tariffMonth of this.tariffMonths) {
          res.push({
            label: tariffMonth.label,
            value: tariffMonth.value,
            conditions: null,
          });
        }
      }

      return res;
    }
  },

  methods: {
    onOpenDetailTransaction(transaction) {
      this.isOpenDetailTransaction = true;
      this.showDetailTransaction = transaction;
    },

    onCloseDetailTransaction() {
      this.isOpenDetailTransaction = false;
      this.showDetailTransaction = null;
    },

    onSelectTariff() {
      if (parseInt(this.selectedTariff.type_paid) === 1) {
        this.openSelectPaymentProvider = true;
        this.loadPaymentProviders();
      } else {
        this.onChangeTariff();
      }
    },

    onPayNow(paymentProvider) {
      this.form.payment_provider_id = paymentProvider.id

      this.onChangeTariff();
    },

    onShowChangeTariff() {
      this.isChangeTariff = true;
    },

    onChangeTariff() {
      this.formErrorMessage = ''
      this.formProcessing = true

      const tariffId = this.selectedTariff.id

      this.form.months = this.selectedMonths.value;

      if (this.formPackages.length) {
        for (const formPackage of this.formPackages) {
          this.form.additional_tariffs.push(formPackage.id);
        }
      }

      this.$api.post(`/tariffs/${tariffId}/select`, this.form).then((result) => {
        if (parseInt(result.data.status) === 1) {
          window.location.href = result.data.data.url
        } else {
          this.formErrorMessage = result.data.message
        }

        this.formProcessing = false
      }).catch(e => {
        alert(e.message)
        this.error = e
        this.formProcessing = false
      })
    },

    loadExchanges() {
      this.$api.get('/exchanges').then((result) => {
        this.exchanges = result.data.data
        this.loading = false
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },

    loadTariffs() {
      this.$api.get('/tariffs?bot_id=1').then((result) => {
        this.tariffs = result.data.data
        this.selectedTariff = this.tariffs[0]
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },

    loadPaymentProviders() {
      this.$api.get('/payment-providers?bot_id=1').then((result) => {
        this.paymentProviders = result.data.data
        this.loadingPaymentProviders = false
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },

    loadPaymentCards() {
      this.$api.get('/user/payment-cards').then((result) => {
        this.paymentCards = result.data;
        this.loadingPaymentCards = false;

        this.$emit('load-payment-cards', this.paymentCards);
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    }
  },

  data() {
    return {
      loading: true,
      loadingPaymentProviders: true,
      loadingTransactions: true,
      loadingPaymentCards: true,
      error: null,
      selectedExchange: null,
      selectedTariff: null,

      tariffs: [],
      exchanges: [],
      paymentProviders: [],
      paymentCards: [],

      tariffMonths: [
        {
          label: '1 ' + this.$t('month'),
          value: 1,
        },
        {
          label: '2 '  + this.$t('months'),
          value: 2,
        },
        {
          label: '3 ' + this.$t('months'),
          value: 3,
        },
        {
          label: '4 ' + this.$t('months'),
          value: 4,
        },
        {
          label: '5 ' + this.$t('t_months'),
          value: 5,
        },
        {
          label: '6 ' + this.$t('t_months'),
          value: 6,
        },
        {
          label: '7 ' + this.$t('t_months'),
          value: 7,
        },
        {
          label: '8 ' + this.$t('t_months'),
          value: 8,
        },
        {
          label: '9 ' + this.$t('t_months'),
          value: 9,
        },
        {
          label: '10 ' + this.$t('t_months'),
          value: 10,
        },
        {
          label: '11 ' + this.$t('t_months'),
          value: 11,
        },
        {
          label: '12 ' + this.$t('t_months'),
          value: 12,
        }
      ],

      selectedMonths: null,

      form: {
        tariff_id: null,
        payment_provider_id: null,
        months: 1,
        additional_tariffs: []
      },

      formPackages: [],

      isOpenDetailTransaction: false,
      showDetailTransaction: null,

      formProcessing: false,
      formErrorMessage: '',

      openSelectPaymentProvider: false,

      isChangeTariff: false,

      formattedAmount
    }
  },

  mounted() {
    this.selectedMonths = this.months[0];

    this.loadExchanges();
    this.loadTariffs();
    this.loadPaymentCards();
  }
}
</script>