<template>
  <div class="mb-3 rounded-xl overflow-hidden px-4 py-2" :class="[$theme.cardBg]">
    <div v-for="(value, key, index) in ref_stats.total.details" :key="key">
      <div v-if="key !== 'all'" class="py-2">
        <div class="flex justify-between items-center mb-1">
          <span class="text-sm font-medium" :class="[$theme.cardLabelTextColor]">
            {{ showEmoji(key) }} {{ $t(key) }}
          </span>
          <span class="text-sm" :class="[$theme.cardLabelTextColor]">
            {{ value }}
          </span>
        </div>
        <div class="w-full rounded-full h-3" :class="[$theme.navActiveBg]">
          <div
              :class="barColors[index % barColors.length]"
              class="h-3 rounded-full"
              :style="{ width: `${calculatePercentage(value)}%` }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['ref_stats'],
  data() {
    return {
      barColors: [
        'bg-gray-700',
        'bg-red-500',
        'bg-orange-500',
        'bg-blue-500',
        'bg-purple-500',
        'bg-green-500',
      ]
    };
  },
  computed: {
    totalCount() {
      // Суммируем все значения, исключая ключ "all"
      return Object.entries(this.ref_stats.total.details)
          .filter(([key]) => key !== 'all') // Исключаем ключ "all"
          .reduce((sum, [, value]) => sum + parseFloat(value), 0);
    }
  },
  methods: {
    calculatePercentage(value) {
      // Рассчитываем процент от общего количества
      return this.totalCount > 0
          ? ((parseFloat(value) / this.totalCount) * 100).toFixed(2)
          : 0;
    },

    showEmoji(key) {
      if (key === 'started') {
        return '🤖';
      }

      if (key === 'term_apply') {
        return '📄';
      }

      if (key === 'contact_filled') {
        return '☎️';
      }

      if (key === 'tariff_payed') {
        return '✅';
      }

      if (key === 'trade_started') {
        return '🎉';
      }

      return '';
    }
  }
};
</script>