<template>
  <div class="relative" style="height: 120px;">
    <!-- Горизонтальная линия (ось X) -->
    <div class="absolute left-0 right-0 top-1/2 border-t" :class="[$theme.navBorderColor]"></div>

    <!-- Столбцы графика -->
    <div class="relative flex justify-around items-center h-full">
      <div
          v-for="(value, index) in chart_data.pnl"
          :key="index"
          class="relative flex flex-col items-center"
          @mouseenter="showTooltip(value, chart_data.dates[index], index)"
          @mouseleave="hideTooltip"
      >
        <div
            :style="{
            height: `${Math.abs(value) / maxPnl * 60}px`,  // Максимальная высота 60px
            position: 'absolute',
            top: value > 0 ? `calc(50% - ${Math.abs(value) / maxPnl * 60}px)` : 'auto',  // Положительные столбики растут вверх от 50%
            bottom: value < 0 ? `calc(50% - ${Math.abs(value) / maxPnl * 60}px)` : 'auto',  // Отрицательные столбики растут вниз от 50%
          }"
            :class="[
            'transition-all duration-300 cursor-pointer',
            value > 0 ? $theme.bgSuccessPnlColor : $theme.bgDangerColor,
            period === '7days' && 'w-9',
            period === '14days' && 'w-4',
            period === 'month' && 'w-2',
          ]"
        ></div>

        <div
            v-if="tooltip.visible && tooltip.index === index"
            class="absolute bg-gray-800 text-white text-xs px-2 py-1 rounded shadow-lg z-10 w-24"
            :style="{
            bottom: value > 0 ? 'auto' : `calc(-100% - 5px)`,
            top: value > 0 ? `calc(100% + 5px)` : 'auto',
            left: '50%',
            transform: 'translateX(-50%)',
          }"
        >
          <div>{{ formatedDate(tooltip.date) }}</div>
          <div>PnL: {{ roundAmount(tooltip.pnl) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {roundAmount} from '@/helpers/numberHelper'
import {formatedDate} from '@/helpers/dateHelper'

export default {
  props: {
    chart_data: {
      type: Object,
      required: true,
    },
    period: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      tooltip: {
        visible: false,
        index: null,
        pnl: null,
        date: null,
      },
      roundAmount,
      formatedDate
    };
  },
  computed: {
    maxPnl() {
      return Math.max(...this.chart_data.pnl.map((val) => Math.abs(val))) || 1;
    },
  },
  methods: {
    showTooltip(pnl, date, index) {
      this.tooltip = {
        visible: true,
        index,
        pnl,
        date,
      };
    },
    hideTooltip() {
      this.tooltip.visible = false;
    },
  },
};
</script>

<style scoped>
/* Стили для графика и столбиков */
</style>
