<template>
  <div class="pt-4 px-4 pb-20">
    <div class="mb-5 flex items-center justify-between">
      <div class="text-3xl font-bold">
        {{ $t('dashboard') }}
      </div>
      <button @click="onShowDashboardSettings" class="w-8 h-8 rounded-lg flex items-center justify-center" :class="[$theme.placeBg, $theme.cardLabelTextColor]">
        <Cog6ToothIcon class="w-5 h-5 opacity-70" />
      </button>
    </div>

    <div>
      <div class="block">
        <nav class="flex space-x-4 mb-5" aria-label="Tabs">
          <a
              v-for="tab in tabs"
              :key="tab.key"
              href="javascript:void(0)"
              :class="[
              tab.key === activePeriod ? `${$theme.activeTabBg} ${$theme.activeTabTextColor}` : $theme.tabTextColor,
              'rounded-md px-3 py-2 text-sm font-medium'
            ]"
              @click="onChangePeriod(tab.key)"
          >{{ tab.name }}</a>
        </nav>
      </div>

      <div class="rounded-xl overflow-hidden" :class="[$theme.cardBg]">
        <template v-if="loading || chartLoading">
          <ChartPlaceholder />
        </template>
        <template v-else>
          <template v-if="dashboardSettings.chartStyle === 'bar'">
            <TheBarChart :chart_data="chartData" :period="activePeriod" />
          </template>
          <template v-if="dashboardSettings.chartStyle === 'line'">
            <TheChart :chart_data="chartData" />
          </template>
          <dl class="mx-auto border-t grid gap-px grid-cols-2" :class="[$theme.cardBorderColor]">
            <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 px-3 py-3">
              <dt class="text-sm font-medium" :class="[$theme.cardLabelTextColor]">P&L (USDT)</dt>
              <dd :class="[dashboard.percent_pnl < 0 ? $theme.dangerPnlColor : $theme.successPnlColor, 'text-xs font-medium']">
                {{ roundAmount(dashboard.percent_pnl) }}%
              </dd>
              <dd
                  class="w-full flex-none text-xl font-medium tracking-tight"
                  :class="[dashboard.pnl < 0 ? $theme.dangerPnlColor : $theme.successPnlColor]"
              >
                {{ roundAmount(dashboard.pnl)}}
              </dd>
            </div>
            <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 px-3 py-3">
              <dt class="text-sm font-medium" :class="[$theme.cardLabelTextColor]">
                {{ $t('positions') }}
              </dt>
              <dd class="w-full flex-none text-xl font-medium tracking-tight" :class="[$theme.baseTextColor]">
                {{ dashboard.positions || 0 }}
              </dd>
            </div>
          </dl>
        </template>
      </div>

      <div v-if="dashboardSettings.referrals" class="mt-4">
        <div class="mb-1 flex items-center justify-between">
          {{ $t('referrals') }}
          <router-link to="/ref-stats" class="opacity-50 flex items-center text-sm">
            {{ $t('read_more') }}
            <ChevronRightIcon class="w-4 ml-1" />
          </router-link>
        </div>
        <div class="rounded-xl overflow-hidden" :class="[$theme.cardBg]">
          <template v-if="loading">
            <StatCardPlaceholder />
          </template>
          <template v-else>
            <dl class="mx-auto grid gap-px grid-cols-2">
              <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 px-3 py-3">
                <dt class="text-sm font-medium" :class="[$theme.cardLabelTextColor]">
                  {{ $t('income') }}
                </dt>
                <dd
                  class="w-full flex-none text-xl font-medium tracking-tight"
                  :class="[dashboard.referral_income < 0 ? $theme.dangerPnlColor : $theme.successPnlColor]"
                >
                  ${{ dashboard.referral_income }}
                </dd>
              </div>
              <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 px-3 py-3">
                <dt class="text-sm font-medium" :class="[$theme.cardLabelTextColor]">{{ $t('referrals') }}</dt>
                <dd class="w-full flex-none text-xl font-medium tracking-tight" :class="[$theme.baseTextColor]">
                  {{ dashboard.referrals }}
                </dd>
              </div>
            </dl>
          </template>
        </div>
      </div>

      <div v-if="dashboardSettings.faq" class="mt-6">
        <div class="mb-3 rounded-xl overflow-hidden" :class="[$theme.cardBg]">
          <template v-if="faqFeatures">
            <TheFaq :items="faqFeatures.data" />
          </template>
        </div>
        <template v-if="faqFeatures">
          <router-link v-if="faqFeatures" to="/faq" type="button" class="w-full flex items-center justify-center rounded-xl px-3.5 py-2.5" :class="[
              $theme.lightButtonBg,
              $theme.lightButtonTextColor,
          ]">
            {{ $t('find_more') }} <ArrowRightIcon class="ml-2 w-5" />
          </router-link>
        </template>
      </div>
    </div>
  </div>

  <TransitionRoot as="template" :show="openDashboardSettings">
    <Dialog class="relative z-50" @close="openDashboardSettings = false">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      <div class="fixed inset-0 z-50 flex items-end justify-center">
        <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-300"
            enter-from="translate-y-full opacity-0"
            enter-to="translate-y-0 opacity-100"
            leave="transform transition ease-in-out duration-300"
            leave-from="translate-y-0 opacity-100"
            leave-to="translate-y-full opacity-0"
        >
          <DialogPanel class="w-full bg-white rounded-t-2xl px-4 pt-5 pb-4 text-left shadow-xl sm:max-w-lg sm:w-full sm:p-6">
            <div>
              <div class="pb-4">
                <div class="text-gray-900">{{ $t('choise_chart_style') }}</div>
                <div class="mt-3">
                  <fieldset>
                    <RadioGroup v-model="dashboardSettings.chartStyle" class="grid grid-cols-2 gap-x-3">
                      <RadioGroupOption as="template" v-for="chartStyleOption in chartStyleOptions" :key="chartStyleOption.id" :value="chartStyleOption.id" :aria-label="chartStyleOption.title" v-slot="{ active, checked }">
                        <div :class="[active ? `${$theme.radioActiveBorderColor} ring-2 ${$theme.radioActiveRingColor}` : 'border-gray-300', 'relative flex cursor-pointer rounded-lg border bg-white p-3 focus:outline-none outline-none']">
                          <span class="flex flex-col w-full">
                            <template v-if="chartStyleOption.id === 'bar'">
                              <ChartBarIcon class="w-8 h-8 opacity-50" :class="[chartStyleOption.id === dashboardSettings.chartStyle ? $theme.navActiveTextColor2 : 'text-gray-300']" />
                            </template>
                            <template v-if="chartStyleOption.id === 'line'">
                              <ArrowTrendingUpIcon class="w-8 h-8 opacity-50" :class="[chartStyleOption.id === dashboardSettings.chartStyle ? $theme.navActiveTextColor2 : 'text-gray-300']" />
                            </template>
                            <span class="block text-xs mt-2 text-gray-900">{{ chartStyleOption.title }}</span>
                          </span>
                          <CheckCircleIcon :class="[!checked ? 'invisible' : '', 'size-5', $theme.navActiveTextColor2]" aria-hidden="true" />
                          <span :class="[active ? 'border' : 'border-2', checked ? $theme.radioActiveBorderColor : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']" aria-hidden="true" />
                        </div>
                      </RadioGroupOption>
                    </RadioGroup>
                  </fieldset>
                </div>
              </div>
              <div class="py-4 border-t border-gray-100">
                <SwitchGroup as="div" class="flex items-center">
                  <Switch v-model="dashboardSettings.referrals" :class="[dashboardSettings.referrals ? $theme.switchBgActive : 'bg-gray-200', $theme.switchRingFocus, 'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2']">
                    <span aria-hidden="true" :class="[dashboardSettings.referrals ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                  </Switch>
                  <SwitchLabel as="span" class="ml-3">
                    <span class="text-gray-900">{{ $t('show_ref') }}</span>
                  </SwitchLabel>
                </SwitchGroup>
              </div>
              <div class="py-4 border-t border-gray-100">
                <SwitchGroup as="div" class="flex items-center">
                  <Switch v-model="dashboardSettings.faq" :class="[dashboardSettings.faq ? $theme.switchBgActive : 'bg-gray-200', $theme.switchRingFocus, 'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2']">
                    <span aria-hidden="true" :class="[dashboardSettings.faq ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                  </Switch>
                  <SwitchLabel as="span" class="ml-3">
                    <span class="text-gray-900">{{ $t('show_faq') }}</span>
                  </SwitchLabel>
                </SwitchGroup>
              </div>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import TheChart from "@/components/elem/TheChart.vue";
import TheBarChart from "@/components/elem/TheBarChart.vue";
import TheFaq from "@/components/TheFaq.vue";
import ChartPlaceholder from "@/components/placeholders/ChartPlaceholder.vue";
import StatCardPlaceholder from "@/components/placeholders/StatCardPlaceholder.vue";
import {ArrowRightIcon, ChevronRightIcon} from "@heroicons/vue/24/outline";
import {Cog6ToothIcon, CheckCircleIcon, ChartBarIcon, ArrowTrendingUpIcon} from "@heroicons/vue/20/solid";
import {
  Switch,
  SwitchGroup,
  SwitchLabel,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
  RadioGroup,
  RadioGroupOption
} from "@headlessui/vue";
import {roundAmount} from '@/helpers/numberHelper'

export default {
  components: {
    TheChart,
    TheBarChart,
    TheFaq,
    ArrowRightIcon,
    ChevronRightIcon,
    Cog6ToothIcon,
    CheckCircleIcon,
    ChartBarIcon,
    ArrowTrendingUpIcon,
    ChartPlaceholder,
    StatCardPlaceholder,
    Switch,
    SwitchGroup,
    SwitchLabel,
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    RadioGroup,
    RadioGroupOption
  },

  watch: {
    'dashboardSettings': {
      handler() {
        this.updateUserSettings();
      },
      deep: true,
    },
  },

  data() {
    return {
      loading: true,
      chartLoading: true,
      error: null,

      openDashboardSettings: false,
      dashboardSettings: {
        chartStyle: 'line',
        referrals: true,
        faq: true,
      },

      chartStyleOptions: [
        {
          id: 'line',
          title: this.$t('line_chart')
        },
        {
          id: 'bar',
          title: this.$t('bar_chart')
        },
      ],

      faqFeatures: null,
      dashboard: null,
      chartData: null,

      activePeriod: '7days',
      tabs: [
        {
          name: this.$t('7days'),
          key: '7days',
        },
        {
          name: this.$t('14days'),
          key: '14days',
        },
        {
          name: this.$t('30days'),
          key: 'month',
        },
      ],
      roundAmount
    }
  },

  methods: {
    onShowDashboardSettings() {
      this.openDashboardSettings = true;
    },

    onChangePeriod(period) {
      this.activePeriod = period;

      this.fetchData();
    },

    fetchData() {
      this.loading = true;
      console.log('Fetching data for period:', this.activePeriod);

      this.$api.get('/dashboard', {
        params: {
          period: this.activePeriod
        }
      }).then(result => {
        console.log('res', result.data)
        this.dashboard = result.data;
        this.loading = false

        this.fetchChart();
      })
    },

    fetchChart() {
      this.chartLoading = true;

      this.$api.get('/dashboard/chart', {
        params: {
          period: this.activePeriod
        }
      }).then(result => {
        console.log('res', result.data)
        this.chartData = result.data;
        this.chartLoading = false;
      })
    },

    onLoadFaqFeatures() {
      this.$api.get('/faq/features').then(result => {
        console.log('res', result.data)
        this.faqFeatures = result.data;
      })
    },

    updateUserSettings() {
      this.$api
          .post("/user/update-settings", {
            dashboard: this.dashboardSettings,
          })
          .then((result) => {
            console.log("Settings updated.", result);
          })
          .catch((e) => {
            console.error(e);
            this.error = e;
          });
    },
  },

  mounted() {
    this.dashboardSettings = this.$user.settings.dashboard;

    this.fetchData();
    this.onLoadFaqFeatures();
  }
}
</script>
