import { createStore } from 'vuex'

export default createStore({
    state: {
        count: 0,
        enabledSignals: []
    },
    mutations: {
        increment(state) {
            state.count++
        },

        updateEnabledSignals(state, payload) {
            state.enabledSignals = payload
        },
    },
    actions: {
        increment({ commit }) {
            commit('increment')
        }
    },
    modules: {
    }
})
